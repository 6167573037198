export const CompanyLogos: React.FC = () => {
    return (
      <div className="flex justify-between flex-wrap pb-1 px-2">
        <img
          src="https://www.greener.com.br/wp-content/uploads/2020/11/af-id-greener-positivo-1024x190.png.webp"
          className="w-[134px] h-[25px]"
          alt="Greener Logo"
        />
        <img
          src="https://greendex.info/static/media/logo-dark.f14ecb18bec4284d4d4de904fccd435f.svg"
          className="w-[150px]"
          alt="Greendex Logo"
        />
      </div>
    );
  };