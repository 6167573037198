export const COUPONS = [
    {
      reference: "DADOESTRATÉGICO50",
      title: "DADOESTRATÉGICO50",
      totalPrice: "4.998,50",
      discountValue: "4.998,50",
      percentage: 50,
      link: 'https://payment-link-v3.pagar.me/pl_YaJ2zO4GvA1VEByf7PULX3l7mQoDkKw5'
    },
    {
      reference: "DADOESSENCIAL15",
      title: "DADOESSENCIAL15",
      totalPrice: "8.497,45",
      discountValue: "1.499,55",
      percentage: 15,
      link: 'https://payment-link-v3.pagar.me/pl_XJaGEQd42eow5bRc0IolV10l3ZLkzRvj'
    },
  ];
  
  export const DEFAULT_PAYMENT_LINK = "https://payment-link-v3.pagar.me/pl_yjGKZvngWYl1BdWUgdiPMJaPqL7Nrwpm";
  export const ORIGINAL_PRICE = "12.470,00";
  export const DEFAULT_PRICE = "9.997,00";
  export const TERMS_LINK = "https://drive.google.com/file/d/1TROiQrTfjrLuzIFukoFLrqDkyC4aFI2I/view";
 