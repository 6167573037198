import { AnimatePresence, motion } from "motion/react";
import React from "react";
import { i18n } from ".";
import { COUPONS } from "./constants";
import { Coupon } from "./types";

interface CouponInputProps {
  onApplyCoupon: (coupon: Coupon | null) => void;
  coupon: Coupon | null;
  t: (typeof i18n)["en"];
}

export const CouponInput: React.FC<CouponInputProps> = ({
  onApplyCoupon,
  coupon,
  t,
}) => {
  const [couponField, setCouponField] = React.useState("");
  const [notFoundCoupon, setNotFoundCoupon] = React.useState(false);

  const handleCouponApplication = () => {
    if (couponField.trim() === "") return setNotFoundCoupon(true);
    setNotFoundCoupon(false);

    const foundCoupon = COUPONS.find((c) => c.reference === couponField);
    onApplyCoupon(foundCoupon || null);

    if (!foundCoupon) return setNotFoundCoupon(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleCouponApplication();
    }
  };

  React.useEffect(() => {
    setNotFoundCoupon(false);
  }, [couponField]);

  return (
    <React.Fragment>
      <div className="flex gap-2 items-center mt-6">
        <div className="flex-col flex w-full">
          <label className="text-sm font-medium">{t.coupon}</label>
          <input
            type="text"
            onKeyDown={handleKeyDown}
            value={coupon ? coupon.reference : couponField}
            onChange={(e) => setCouponField(e.target.value)}
            placeholder={t.typeYourCoupon}
            disabled={Boolean(coupon?.reference)}
            className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-base shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-900 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
          />
        </div>
        <button
          type="button"
          disabled={Boolean(coupon?.reference)}
          onClick={handleCouponApplication}
          className="rounded-md px-3 mt-auto text-sm font-medium h-9 bg-[#00414C] hover:bg-[#00414C]/90 text-white disabled:bg-[#00414C]/80 disabled:cursor-not-allowed"
        >
          {t.validate}
        </button>
      </div>
      <AnimatePresence>
        {notFoundCoupon && (
          <motion.div
            initial={{ height: 0, marginTop: 0, opacity: 0 }}
            animate={{ height: "auto", marginTop: "1rem", opacity: 1 }}
            exit={{ height: 0, marginTop: 0, opacity: 0 }}
            className="text-rose-500 text-sm"
          >
            {t.invalidCoupon}
          </motion.div>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
};
