import { Route, Routes } from "react-router-dom";

import Admin from "./views/admin";
import AdminBlog from "./views/admin-blog";
import AdminUser from "./views/admin-user";
import AdminUsersList from "./views/admin-users-list";
import Armazenamento from "./views/armazenamento";
import ArmazenamentoPartners from "./views/armazenamento-partners";
import Blog from "./views/blog";
import BlogEditor from "./views/blog-editor";
import BlogPost from "./views/blog-post";
import Compras from "./views/compras";
import Contact from "./views/contact";
import DashProducaoStage from "./views/dash-producao-stage";
import ErrorView from "./views/error";
import ForgotPass from "./views/forgot-pass";
import GdData from "./views/gd-data";
import HomeView from "./views/home";
import Login from "./views/login";
import MonitorApi from "./views/monitor-api";
import MyData from "./views/my-data";
import MyProducts from "./views/my-products";
import PlansAndValues from "./views/plans-and-values";
import PricesApi from "./views/prices-api";
import ProdutosInternos from "./views/produtos-internos";
import ProdutosInternosAdd from "./views/produtos-internos-add";
import ProdutosInternosEdit from "./views/produtos-internos-edit";
import Profile from "./views/profile";
import Register from "./views/register";
import RegisterSocial from "./views/register-social";
import Relatorio from "./views/relatorio";
import RelatorioList from "./views/relatorio-list";
import RelatorioNew from "./views/relatorio-new";
import ResetPass from "./views/reset-pass";

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<HomeView />} />
      {/* <Route path="produto">
                <Route path="" element={<ErrorView />} />
                <Route path="calculadora-gd" element={<CalculadoraGd />} />
                <Route path="precos" element={<Precos />} />
                <Route path="radar-gd" element={<RadarGd />} />
                <Route path="mapeamento-tarifas" element={<MapeamentoTarifas />} />
                <Route path="produtividade-atratividade" element={<ProdutividadeAtratividade />} />
                <Route path=":id" element={<ProductView />} />
            </Route>
            <Route path="pacote/pacote-gd" element={<PacoteGd />} /> */}
      {/* <Route path="pacote/:id" element={<PackageView />} /> */}
      <Route path="compras" element={<Compras />} />
      <Route
        path="estudo-estrategico-de-armazenamento-de-energia-2025"
        element={<Armazenamento />}
      />
      <Route path="parceiros">
        <Route
          path="estudo-estrategico-de-armazenamento-de-energia-2025"
          element={<ArmazenamentoPartners />}
        />
      </Route>
      <Route path="login" element={<Login />} />
      <Route path="cadastro-social" element={<RegisterSocial />} />
      <Route path="cadastro" element={<Register />} />
      <Route path="esqueci-minha-senha" element={<ForgotPass />} />
      <Route path="redefinir-senha" element={<ResetPass />} />
      <Route path="meus-produtos" element={<MyProducts />} />
      <Route path="perfil" element={<Profile />} />
      <Route path="meus-dados" element={<MyData />} />
      {/* <Route path="historico-de-compras" element={<PurchasesHistoric />} />
            <Route path="gerenciar-assinaturas" element={<ManageSubscriptions />} /> */}
      <Route path="contato" element={<Contact />} />
      {/* <Route path="compra/:type/:id" element={<Purchase />} /> */}
      <Route path="planos-e-valores" element={<PlansAndValues />} />
      <Route path="admin">
        <Route path="" element={<Admin />} />
        <Route path="usuario" element={<AdminUsersList />} />
        <Route path="usuario/:id" element={<AdminUser />} />
        <Route path="blog" element={<AdminBlog />} />
        <Route path="blog/add" element={<BlogEditor />} />
        <Route path="blog/:id" element={<BlogEditor />} />
        <Route path="produtos-internos" element={<ProdutosInternos />} />
        <Route path="produtos-internos/add" element={<ProdutosInternosAdd />} />
        <Route
          path="produtos-internos/:id"
          element={<ProdutosInternosEdit />}
        />
        <Route path="*" element={<ErrorView />} />
      </Route>
      <Route path="dados-gd" element={<GdData />} />
      {/* <Route path="checkout">
                <Route path="" element={<ErrorView />} />
                <Route path="identificacao" element={<CheckoutIdentification />} />
                <Route path="identificacao-usuario" element={<CheckoutIdentificationLogged />} />
                <Route path="forma-de-pagamento" element={<CheckoutPaymentMethod />} />
                <Route path="resumo" element={<CheckoutSummary />} />
                <Route path="boleto" element={<CheckoutBoleto />} />
                <Route path="pix" element={<CheckoutPix />} />
                <Route path="cartao" element={<CheckoutCartao />} />
                <Route path="*" element={<ErrorView />} />
            </Route> */}
      <Route path="prices-api" element={<PricesApi />} />
      <Route path="monitor-api" element={<MonitorApi />} />
      <Route path="blog">
        <Route path="" element={<Blog />} />
        <Route path=":id" element={<BlogPost />} />
      </Route>
      <Route
        path="dash-prices-stage-producao"
        element={<DashProducaoStage />}
      />
      <Route path="relatorio-distribuidores" element={<RelatorioList />} />
      <Route path="relatorio-distribuidores/new" element={<RelatorioNew />} />
      <Route path="relatorio-distribuidores/:id" element={<Relatorio />} />
      <Route path="*" element={<ErrorView />} />
    </Routes>
  );
};

export default AppRoutes;
