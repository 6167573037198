import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CompanyLogos } from "./company-logos";
import { COUPONS, DEFAULT_PAYMENT_LINK } from "./constants";
import { CouponInput } from "./coupon-input";
import { PriceDisplay } from "./price-display";
import { TermsCheckbox } from "./terms-checkbox";
import { Coupon } from "./types";

export const i18n = {
  pt: {
    productName: "Estudo Estratégico de Armazenamento de Energia 2025",
    productShortName: "Estudo de armazenamento de energia",
    thankYou: "Obrigado pelo seu interesse pelo",
    proceedText:
      "Para garantir sua participação, aceite os termos de uso abaixo e prossiga com o pagamento seguro",
    continuePayment: "continuar para pagamento seguro",
    securePayment: "Seu pagamento é 100% seguro com Pagar.me.",
    productAlt: "Produto",
    toPay: "Total a pagar",
    iAgree: "Confirmo que li e concordo com os",
    termsAndConditions: "termos e condições de uso",
    ofGreenerStudies: " dos estudos Greener and Greendex.",
    youNeedAgree: "É necessário aceitar os termos para continuar.",
    coupon: "Cupom",
    typeYourCoupon: "digite seu cupom",
    validate: "validar",
    invalidCoupon: "Cupom de desconto inserido é inválido",
    viewingPageIn: "Visualizando a página em Português",
    changeTo: "mudar para inglês",
  },
  en: {
    productName: "Strategic Market Report 2025: Energy Storage",
    productShortName: "Strategic Market Report 2025: Energy Storage",
    thankYou: "Thank you for your interest in",
    proceedText:
      "To secure your participation, accept the terms below and proceed with secure payment",
    continuePayment: "continue to secure payment",
    securePayment: "Your payment is 100% secure with Pagar.me.",
    productAlt: "Product",
    toPay: "Total to pay",
    iAgree: "I confirm that I have read and agree with the",
    termsAndConditions: "terms and conditions of use",
    ofGreenerStudies: " of the Greener and Greendex studies.",
    youNeedAgree: "You must accept the terms to continue.",
    coupon: "Coupon",
    typeYourCoupon: "type your coupon",
    validate: "validate",
    invalidCoupon: "Discount coupon entered is invalid",
    viewingPageIn: "Viewing page in English",
    changeTo: "change to Portuguese",
  },
};

const useUserLanguage = () => {
  const [userLang, setUserLang] = useState<"pt" | "en">("pt");

  useEffect(() => {
    const browserLang = navigator.language.startsWith("pt") ? "pt" : "en";
    setUserLang(browserLang);
  }, []);

  return userLang;
};

const ArmazenamentoPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const couponFromQuery = searchParams.get("cupom");

  const browserLanguage = useUserLanguage();
  const urlLanguage = searchParams.get("locale") as "pt" | "en" | null;
  const language = urlLanguage || browserLanguage;
  const t = i18n[language];

  const [showError, setShowError] = useState(false);
  const [checkboxState, setCheckboxState] = useState(false);
  const [coupon, setCoupon] = useState<Coupon | null>(null);

  const productImageUrl =
    "https://pages.greatpages.com.br/armazenamento-2025.greener.com.br/1739987722/imagens/desktop/1290675_1_173876219667a367d43f33d.png";

  const handleSubmit = (e: React.FormEvent) => {
    if (!checkboxState) {
      e.preventDefault();
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  useEffect(() => {
    if (checkboxState) {
      setShowError(false);
    }
  }, [checkboxState]);

  useEffect(() => {
    if (!couponFromQuery) return;
    const foundCoupon = COUPONS.find((c) => c.reference === couponFromQuery);
    if (foundCoupon) {
      setCoupon(foundCoupon);
    }
  }, [couponFromQuery]);

  return (
    <div className="w-screen flex-col min-h-screen h-full sm:bg-neutral-100 max-sm:bg-white flex items-center justify-center p-2 sm:p-4">
      <div className="sm:rounded-xl bg-white max-w-lg w-full sm:shadow p-2 overflow-clip">
        <div className="relative">
          <img
            src={productImageUrl}
            className="absolute scale-110 left-1/2 top-0 w-full -translate-x-1/2 rounded-lg object-cover opacity-50 z-0 blur-xl transition-opacity"
          />
          <img
            src={productImageUrl}
            className="rounded-lg relative z-10"
            alt="Product"
          />
        </div>

        <form
          action={DEFAULT_PAYMENT_LINK}
          onSubmit={handleSubmit}
        >
          <div className="py-4 px-2">
            <h2 className="text-neutral-800 text-sm font-medium">
              {t.thankYou}
            </h2>
            <h1 className="leading-none mt-2 text-4xl font-['Bebas_Neue'] text-neutral-800">
              {t.productName}
            </h1>

            <p className="text-neutral-600 text-sm mt-2">{t.proceedText}</p>

            <div className="rounded-xl shadow p-3 mb-2 mt-4 bg-neutral-50 outline-1 outline outline-neutral-200 flex flex-col">
              {/* <PriceDisplay t={t} coupon={coupon} />
              <CouponInput t={t} onApplyCoupon={setCoupon} coupon={coupon} /> */}
              <TermsCheckbox
                t={t}
                checked={checkboxState}
                onChange={setCheckboxState}
                showError={showError}
              />

              <button
                className="w-full text-white py-2.5 mt-4 font-medium bg-[#00414C] hover:bg-[#003746] transition-colors rounded-lg text-sm"
                type="submit"
              >
                {t.continuePayment}
              </button>
            </div>
            <p className="text-xs text-neutral-600 text-center mt-4">
              {t.securePayment}
            </p>
          </div>
        </form>

        <CompanyLogos />
      </div>
      <div className="py-3 flex justify-end w-full max-w-lg gap-2 rounded-lg">
        <span className="text-sm text-neutral-800">{t.viewingPageIn} </span>
        <button
          onClick={() => navigate(`?locale=${language === "en" ? "pt" : "en"}`)}
          className="bg-none underline underline-offset-2 text-sm text-neutral-800"
        >
          {t.changeTo}
        </button>
      </div>
    </div>
  );
};

export default ArmazenamentoPage;
