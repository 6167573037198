import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
// import { FloatingWhatsApp } from 'react-floating-whatsapp';

import AppRoutes from "./app-routes";
import Footer from "./components/footer/footer";
import Navbar from "./components/navbar/navbar";

const GA_MEASUREMENT_ID = "G-LQLG6HW232"; // OUR_TRACKING_ID
ReactGA.initialize(GA_MEASUREMENT_ID);

const App = () => {
  const location = useLocation();
  const [showNavbarAndFooter, setShowNavbarAndFooter] = useState(true);

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/cadastro-social" ||
      location.pathname === "/cadastro" ||
      location.pathname === "/esqueci-minha-senha" ||
      location.pathname === "/redefinir-senha" ||
      location.pathname ===
        "/estudo-estrategico-de-armazenamento-de-energia-2025" ||
      location.pathname ===
        "/parceiros/estudo-estrategico-de-armazenamento-de-energia-2025" ||
      location.pathname === "/boletim-importacao-2025"
    ) {
      setShowNavbarAndFooter(false);
    } else {
      setShowNavbarAndFooter(true);
    }

    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + window.location.search,
      title: location.pathname,
    });
  }, [location]);

  return (
    <>
      {showNavbarAndFooter && <Navbar />}
      <AppRoutes />
      {/* {location.pathname !== "/meus-produtos" &&
      !location.pathname.includes("/produto/") && (
        <FloatingWhatsApp
          phoneNumber="+5535999295450"
          avatar={avatar}
          accountName="Marina"
          statusMessage="Normalmente responde em instantes"
          chatMessage="Olá, sou a Marina! Você tem alguma pergunta? Ficarei feliz em te ajudar 🙂"
          placeholder="Digite sua mensagem..."
          allowEsc
          allowClickAway
        />
      )} */}
      {showNavbarAndFooter && <Footer />}
    </>
  );
};
export default App;
