import { AnimatePresence, motion } from "motion/react";
import React from "react";
import { i18n } from ".";
import { TERMS_LINK } from "./constants";

interface TermsCheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  showError: boolean;
  t: (typeof i18n)["en"];
}

export const TermsCheckbox: React.FC<TermsCheckboxProps> = ({
  checked,
  onChange,
  showError,
  t,
}) => {
  return (
    <div className="mt-4">
      <label
        htmlFor="confirm-terms"
        className="flex flex-row group items-start gap-2 cursor-pointer w-full m-0"
      >
        <input
          type="checkbox"
          id="confirm-terms"
          className="hidden flex-shrink-0 peer"
          onChange={() => onChange(!checked)}
          checked={checked}
        />
        <div className="w-7 h-5 rounded outline-1 outline outline-neutral-300 shadow peer-checked:bg-[#00414C] hover:ring-2 ring-[#00414C] ring-offset-1 flex items-center justify-center transition-colors">
          <svg
            className="w-4 h-4 text-white transition-opacity"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <span className="text-neutral-800 leading-relaxed text-sm font-medium">
          {t.iAgree}{" "}
          <a
            className="text-blue-500 hover:text-blue-500 hover:underline underline-offset-1"
            rel="noopener"
            target="_blank"
            href={TERMS_LINK}
          >
            {t.termsAndConditions}
          </a>{" "}
          {t.ofGreenerStudies}
        </span>
      </label>
      <AnimatePresence>
        {showError && (
          <motion.div
            initial={{ height: 0, marginTop: 0, opacity: 0 }}
            animate={{ height: "auto", marginTop: "1rem", opacity: 1 }}
            exit={{ height: 0, marginTop: 0, opacity: 0 }}
            className="text-rose-500 text-sm"
          >
            {t.youNeedAgree}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
