import { motion } from "motion/react";
import React from "react";
import { DEFAULT_PRICE, ORIGINAL_PRICE } from "./constants";
import { Coupon } from "./types";
import { i18n } from ".";

interface PriceDisplayProps {
  coupon: Coupon | null;
  t: typeof i18n['en']
}

export const PriceDisplay: React.FC<PriceDisplayProps> = ({ coupon, t }) => {
  return (
    <>
      <div className="justify-between flex">
        <span className="text-sm">{t.productShortName}</span>
        <div className="flex flex-col items-end">
          <span className="text-xs text-neutral-800 line-through">
            R$ {ORIGINAL_PRICE}
          </span>
          <span className="text-sm font-medium text-neutral-800">
            R$ {DEFAULT_PRICE}
          </span>
        </div>
      </div>

      {coupon && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          className="justify-between flex my-1"
        >
          <span className="text-sm text-neutral-800">Cupom - {coupon.title}</span>
          <div className="flex flex-col items-end">
            <span className="text-sm font-medium text-[#00414C]">
              - R$ {coupon.discountValue} (-{coupon.percentage}%)
            </span>
          </div>
        </motion.div>
      )}

      <hr className="my-2" />

      <div className="justify-between flex">
        <span className="text-neutral-800 font-semibold">{t.toPay}</span>
        <div className="flex flex-col items-end">
          <span className="font-semibold text-[#00414C]">
            R$ {coupon ? coupon.totalPrice : DEFAULT_PRICE}
          </span>
        </div>
      </div>
    </>
  );
};
